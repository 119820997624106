<template>
  <div>
    <portal to="page-top-title">Edit Category #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'contactLists-categories-single', params: { id: id } }" icon="fa-eye" text>Back to Category</btn>
    </portal>

    <box-form action="edit" data-prop="formData" redirect="contactLists-categories-list" :url="`/contact-lists/categories/${id}`" :loaded="loaded" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input>
            <form-group-select name="group_id" title="Group" :model="formData.group_id"
                               :options="groups" :disabled="!groups.length" required/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import Btn from '@/views/components/simple/Btn'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import Form from '@/modules/utils/Form'

export default {
  name: 'ContactListsCategoriesEdit',
  metaInfo () {
    return { title: `ContactLists | Category #${this.id} | Edit` }
  },
  components: {
    BoxForm,
    Btn,
    FormGroupInput,
    FormGroupSelect
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      loaded: false,
      groups: [],
      formData: {
        name: Form.types.input(),
        group_id: Form.types.select()
      }
    }
  },
  created () {
    this.loadEntity()
    this.loadGroups()
  },
  methods: {
    loadEntity () {
      this.$http
        .get(`/contact-lists/categories/${this.id}`)
        .then((res) => {
          const { data: { data } } = res

          this.formData.name.value = data.name
          this.formData.group_id.value = { id: data.group.id, name: data.group.name }

          this.loaded = true
        })
    },
    loadGroups () {
      this.$http
        .get('/category-groups', { params: { no_pages: 1 } })
        .then((res) => {
          const { data: { data } } = res

          this.groups = data.map((group) => { return { id: group.id, name: group.name } })
        })
    }
  }
}
</script>
